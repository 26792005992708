<template>
  <div>
    <v-card-title class="pa-1">{{$t('versionHistory')}}</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="px-1">
      <v-simple-table dense class="bordered--table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" width="15%">{{ $t('versionLabel')}}</th>
              <th class="text-left" width="10%">{{ $t('modifiedOn') }}</th>
              <th class="text-left" width="15%">{{ $t('modifiedBy') }}</th>
              <th class="text-left" width="10%">{{ $t('size') }}</th>
              <!-- <th class="text-left" width="50%">{{ $t('comments') }}</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in versionHistory" :key="i">
              <td>{{item.versionlabel}}</td>
              <!-- <td><a @click="openVersionLink(item.url)" style="text-decoration: underline;">{{item.versionlabel}}</a></td> -->
              <td><span class="d-inline-block text-truncate">{{$formatter.formatDate(item.modified_on, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`)}} </span></td>
              <td>{{item.modifiedby}}</td>
              <td>{{item.size}}</td>
              <!-- <td style="word-break: break-all;">{{item.checkincomment}}</td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </div>
</template>
<script>
export default {
  props: ['versionHistory'],
  methods: {
    openVersionLink (url) {
      window.open(url, '_blank')
    }
  }
}
</script>
